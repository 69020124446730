.table {
  display: block;
  overflow-x: scroll;
  max-height: 70vh;
  border: 1px solid var(--bs-table-border-color);

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: white;

      &:nth-child(1) {
        left: 0px;
        z-index: 1001;
      }
    }
  }

  tbody {
    th {
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: white;
    }
  }

  th {
    min-width: 260px;
  }
  th, td {
    vertical-align: middle;
  }
}

.highlight {
  background-color: #2eca81 !important;
}
