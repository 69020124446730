body,
html {
    font-family: Calibri, sans-serif;
}

h1 {
    font-size: 30px;
}

.navbar {
    min-height: 101px;
}

.navbar-nav {
    padding-top: 20px;
}

.main-container {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
