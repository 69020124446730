.input-group {
  flex-wrap: nowrap;
}

.multiselect {
  background-color: white;

  :global(.optionListContainer) {
    z-index: 10;
  }

  :global(.search-wrapper) {
    white-space: break-spaces !important;
  }

  :global(.chip) {
    white-space: normal;
  }
}

.checkbox {
  z-index: 20;
}
