.group {
  margin-top: 10px;
}

.item {
  padding: 5px 0;

  button {
    margin-right: 10px;
  }
}
